import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header({ user, onSignOut, onAuthClick, onProfileClick }) {
  console.log('Header rendering with user:', user);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-blue-600 text-white shadow-md relative z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="flex items-center relative">
          <img 
            src="https://locumdemo.xyz/images/site/locumlogo.png" 
            alt="Get Locum Logo" 
            className="h-24 mr-2 absolute -bottom-10"
          />
          <Link to="/" className="text-2xl font-bold ml-28">Get Locum!</Link>
        </div>
        <nav className="flex space-x-4 items-center">
          <Link to="/im-a-doctor" className="bg-white text-blue-600 px-4 py-2 rounded-full hover:bg-blue-100 transition duration-300">I'm a Doctor</Link>
          <Link to="/im-a-locum" className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-400 transition duration-300">I'm a Locum</Link>
          {user ? (
            <div className="relative" ref={profileMenuRef}>
              <button 
                onClick={() => setShowProfileMenu(!showProfileMenu)}
                className="w-10 h-10 rounded-full bg-pink-200 flex items-center justify-center focus:outline-none"
              >
                <span className="text-blue-600 font-bold">{user.email[0].toUpperCase()}</span>
              </button>
              {showProfileMenu && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-50">
                  <button onClick={onProfileClick} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</button>
                  <Link to="/credentials" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Credentials</Link>
                  <Link to="/schedule" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Schedule</Link>
                  <div className="border-t border-gray-100"></div>
                  <button 
                    onClick={onSignOut} 
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button 
              onClick={onAuthClick}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-400 transition duration-300"
            >
              Sign In
            </button>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;