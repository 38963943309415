import React from 'react';

function OpportunityCard({ opportunity }) {
  // Function to format pay as $Xk/month
  const formatPay = (pay) => {
    if (!pay && pay !== 0) return 'N/A';
    const roundedPay = Math.round(pay / 1000);
    return `$${roundedPay}k/month`;
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 hover:shadow-lg relative">
      {opportunity.isFlagged && (
        <div className="absolute top-0 right-0 w-20 h-20 overflow-hidden">
          <div className="absolute top-0 right-0 -mt-2 -mr-8 w-32 h-8 bg-red-500 text-white text-xs font-bold text-center leading-8 transform rotate-45">
            Featured
          </div>
        </div>
      )}
      <div 
        className="h-48 bg-cover bg-center relative" 
        style={{backgroundImage: `url(${"https://locumdemo.xyz"+opportunity.thumbnailUrl || opportunity.image})`}}
      >
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
          <h3 className="text-xl font-semibold">{opportunity.location}</h3>
          <p className="text-sm">{opportunity.specialty} - {formatPay(opportunity.payPerMonth)}</p>
        </div>
      </div>
      <div className="p-4">
        <p className="text-sm text-gray-600 mb-2">{opportunity.dateRange}</p>
        <div className="flex flex-wrap gap-2">
          {opportunity.travel && (
            <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">Travel Paid</span>
          )}
          {opportunity.housing && (
            <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded-full">Housing Provided</span>
          )}
          {opportunity.signingBonus && (
            <span className="text-xs bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full">Signing Bonus</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default OpportunityCard;