import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function HeroSection({ user, opportunities, onAuthClick }) {
  const [carouselItems, setCarouselItems] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);
  const [currentSubheading, setCurrentSubheading] = useState(0);

  const subheadings = [
    "Connect with hospitals and clinics looking for temporary doctors.",
    "Boost your income and gain diverse experience across the country.",
    "Enjoy flexibility, travel, and competitive pay while advancing your career.",
    "Explore new opportunities, balance your lifestyle, and expand your professional network."
  ];

  useEffect(() => {
    console.log('Opportunities passed to HeroSection:', opportunities);
    if (Array.isArray(opportunities) && opportunities.length > 0) {
      const filteredOpps = opportunities.filter(opp => opp && opp.bannerUrl);
      const randomOpps = filteredOpps.sort(() => 0.5 - Math.random()).slice(0, 3);
      const items = randomOpps.map((opp, index) => ({
        id: opp.id,
        image: `https://locumdemo.xyz${opp.bannerUrl}`,
        alt: `Opportunity ${index + 1}`,
        color: `bg-blue-${(index % 3 + 1) * 100}`,
        location: opp.location || 'Unknown Location',
        specialty: opp.specialty || 'Unknown Specialty',
        payPerMonth: opp.payPerMonth,
        dateRange: opp.daterange || 'Dates TBD',
        travel: opp.travel,
        housing: opp.housing,
        signingBonus: opp.signingBonus
      }));
      console.log('Carousel items:', items);
      setCarouselItems(items);
    } else {
      console.log('No valid opportunities available');
      setCarouselItems([]);
    }
  }, [opportunities]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSubheading((prev) => (prev + 1) % subheadings.length);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setDirection(1);
    setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
  };

  const prevSlide = () => {
    setDirection(-1);
    setCurrentSlide((prev) => (prev - 1 + carouselItems.length) % carouselItems.length);
  };

  const getSlideStyle = (index) => {
    if (index === currentSlide) {
      return 'translate-x-0 z-20';
    } else if (
      (direction === 1 && index === (currentSlide + 1) % carouselItems.length) ||
      (direction === -1 && index === (currentSlide - 1 + carouselItems.length) % carouselItems.length)
    ) {
      return `${direction === 1 ? '-translate-x-full' : 'translate-x-full'} z-10`;
    }
    return direction === 1 ? 'translate-x-full' : '-translate-x-full';
  };

  const formatPay = (pay) => {
    if (!pay && pay !== 0) return 'N/A';
    const roundedPay = Math.round(pay / 1000);
    return `$${roundedPay}k/month`;
  };

  return (
    <section className="relative h-3/4-screen overflow-hidden">
      {carouselItems.length > 0 ? (
        carouselItems.map((item, index) => (
          <div
            key={item.id}
            className={`absolute inset-0 w-full h-full transition-transform ease-in-out duration-1000 ${item.color} ${getSlideStyle(index)}`}
          >
            <img src={item.image} alt={item.alt} className="w-full h-full object-cover" />
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center h-full bg-gray-200">
          <p className="text-xl text-gray-600">No opportunities available at the moment.</p>
        </div>
      )}
      {carouselItems.length > 0 && (
        <div className="absolute left-0 right-0 bg-gray-800 bg-opacity-65 text-white p-3 z-40" style={{ top: '80%', height: '15%' }}>
          <h2 className="text-2xl font-bold mb-1">{carouselItems[currentSlide].location}</h2>
          <div className="flex items-center justify-between">
            <p className="text-sm">
              {carouselItems[currentSlide].specialty} // {carouselItems[currentSlide].dateRange}
            </p>
          </div>
        </div>
      )}
      <div className="absolute inset-0 flex flex-col justify-between items-start text-white p-8 md:p-16 z-30">
        <div className="bg-black bg-opacity-50 p-4 rounded-lg">
          <h1 className="text-3xl md:text-4xl font-bold mb-4 text-left">Your Next Locum Opportunity Awaits!</h1>
          <div className="h-12 overflow-hidden relative">
            {subheadings.map((subheading, index) => (
              <p 
                key={index} 
                className={`text-lg md:text-xl absolute top-0 left-0 w-full transition-all duration-500 ease-in-out ${
                  index === currentSubheading ? 'translate-x-0 opacity-100' : 
                  index === (currentSubheading - 1 + subheadings.length) % subheadings.length ? 'translate-x-[-100%] opacity-0' : 'translate-x-full opacity-0'
                }`}
              >
                {subheading}
              </p>
            ))}
          </div>
        </div>
      </div>
      
      {!user && (
        <div className="absolute left-0 right-0 bottom-1/4 flex justify-center z-40">
          <button
            onClick={() => onAuthClick(false)} // false indicates sign-up mode
            className="bg-blue-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-400 transition duration-300"
          >
            Get Started
          </button>
        </div>
      )}

      <button
        onClick={prevSlide}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none z-50"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none z-50"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </section>
  );
}

export default HeroSection;