import React, { useState } from 'react';

function SearchBar({ onSearch }) {
  const [location, setLocation] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [minSalary, setMinSalary] = useState('');
  const [travelPaid, setTravelPaid] = useState(false);
  const [housingProvided, setHousingProvided] = useState(false);
  const [signingBonus, setSigningBonus] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchParams = {
      location,
      specialty,
      travelPaid,
      housingProvided,
      signingBonus
    };

    // Only include minSalary if it's a valid number
    const parsedSalary = parseInt(minSalary, 10);
    if (!isNaN(parsedSalary) && parsedSalary > 0) {
      searchParams.minSalary = parsedSalary;
    }

    onSearch(searchParams);
  };

  const handleMinSalaryChange = (e) => {
    const value = e.target.value;
    // Allow empty string or positive integers
    if (value === '' || /^\d+$/.test(value)) {
      setMinSalary(value);
    }
  };

  const FeatureCapsule = ({ label, isSelected, onClick, color }) => (
    <button
      type="button"
      onClick={onClick}
      className={`px-3 py-1 rounded-full text-sm font-semibold mr-2 mb-2 transition-colors duration-200 ${
        isSelected
          ? `${color} text-white`
          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
      }`}
    >
      {label}
    </button>
  );

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-4 mb-4">
      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full md:w-1/4 px-2 mb-2 md:mb-0">
          <input
            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            type="text"
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/4 px-2 mb-2 md:mb-0">
          <input
            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            type="text"
            placeholder="Specialty"
            value={specialty}
            onChange={(e) => setSpecialty(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/4 px-2 mb-2 md:mb-0">
          <input
            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            type="text"
            placeholder="Minimum Salary"
            value={minSalary}
            onChange={handleMinSalaryChange}
          />
        </div>
        <div className="w-full md:w-1/4 px-2">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full transition duration-300"
            type="submit"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
        <FeatureCapsule
          label="Travel Paid"
          isSelected={travelPaid}
          onClick={() => setTravelPaid(!travelPaid)}
          color="bg-blue-500"
        />
        <FeatureCapsule
          label="Housing Provided"
          isSelected={housingProvided}
          onClick={() => setHousingProvided(!housingProvided)}
          color="bg-green-500"
        />
        <FeatureCapsule
          label="Signing Bonus"
          isSelected={signingBonus}
          onClick={() => setSigningBonus(!signingBonus)}
          color="bg-yellow-500"
        />
      </div>
    </form>
  );
}

export default SearchBar;