import React, { useState, useRef, useEffect } from 'react';

function AuthPopup({ onClose, onSignIn, onSignUp, isSignIn: initialIsSignIn }) {
  const [isSignIn, setIsSignIn] = useState(initialIsSignIn);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signUpStep, setSignUpStep] = useState(1);
  const [accountType, setAccountType] = useState('');
  const [clinicianType, setClinicianType] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');

  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignIn) {
      console.log('Submitting sign in form');
      const success = await onSignIn(email, password);
      if (success) {
        onClose();
      }
    } else {
      if (signUpStep < 3) {
        console.log('Moving to next sign up step:', signUpStep + 1);
        setSignUpStep(signUpStep + 1);
      } else {
        console.log('Submitting sign up form');
        onSignUp({
          email,
          password,
          accountType: accountType === 'clinician' ? 'clinician' : 'employer',
          clinicianType: accountType === 'clinician' ? clinicianType : null,
          phoneNumber,
          country
        });
      }
    }
  };

  const renderSignUpForm = () => {
    switch (signUpStep) {
      case 1:
        return (
          <>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
          </>
        );
      case 2:
        return (
          <>
            <div className="mb-4">
              <label className="block mb-2">I am a:</label>
              <div>
                <label className="inline-flex items-center mr-4">
                  <input
                    type="radio"
                    value="clinician"
                    checked={accountType === 'clinician'}
                    onChange={(e) => setAccountType(e.target.value)}
                    className="mr-2"
                  />
                  Clinician
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="employer"
                    checked={accountType === 'employer'}
                    onChange={(e) => setAccountType(e.target.value)}
                    className="mr-2"
                  />
                  I need a clinician
                </label>
              </div>
            </div>
            {accountType === 'clinician' && (
              <input
                type="text"
                placeholder="Clinician Type"
                value={clinicianType}
                onChange={(e) => setClinicianType(e.target.value)}
                className="w-full p-2 mb-4 border rounded"
              />
            )}
          </>
        );
      case 3:
        return (
          <>
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <input
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={popupRef} className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">{isSignIn ? 'Sign In' : 'Sign Up'}</h2>
        <form onSubmit={handleSubmit}>
          {isSignIn ? (
            <>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 mb-4 border rounded"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 mb-4 border rounded"
              />
            </>
          ) : (
            renderSignUpForm()
          )}
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
            {isSignIn ? 'Sign In' : (signUpStep < 3 ? 'Next' : 'Sign Up')}
          </button>
        </form>
        <p className="mt-4 text-center">
          {isSignIn ? "Don't have an account? " : "Already have an account? "}
          <button
            onClick={() => {
              setIsSignIn(!isSignIn);
              setSignUpStep(1);
            }}
            className="text-blue-500 hover:underline"
          >
            {isSignIn ? 'Sign Up' : 'Sign In'}
          </button>
        </p>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
      </div>
    </div>
  );
}

export default AuthPopup;