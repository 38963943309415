import React, { useState, useEffect } from 'react';

function ProfilePanel({ user, onClose, onSave }) {
  const [profile, setProfile] = useState({
    fullName: '',
    phoneNumber: '',
    country: '',
    additionalSkills: '',
    languages: '',
    personalReferences: ''
  });

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const response = await fetch(`https://locumdemo.xyz/api/user-profile/${user.id}`);
        if (response.ok) {
          const data = await response.json();
          setProfile(prevProfile => ({
            ...prevProfile,
            fullName: data.full_name || '',
            phoneNumber: data.phone_number || '',
            country: data.country || '',
            additionalSkills: data.additional_skills || '',
            languages: data.languages || '',
            personalReferences: data.personalreferences || ''
          }));
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    }

    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(profile);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-4xl w-full m-4">
        <h2 className="text-2xl font-bold mb-4">User Profile</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              name="fullName"
              value={profile.fullName}
              onChange={handleChange}
              placeholder="Full Name"
              className="w-full p-2 border rounded"
            />
            <input
              type="email"
              value={user.email}
              placeholder="Email"
              className="w-full p-2 border rounded"
              readOnly
            />
            <input
              type="tel"
              name="phoneNumber"
              value={profile.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              className="w-full p-2 border rounded"
            />
            <input
              type="text"
              name="country"
              value={profile.country}
              onChange={handleChange}
              placeholder="Country"
              className="w-full p-2 border rounded"
            />
          </div>
          <textarea
            name="additionalSkills"
            value={profile.additionalSkills}
            onChange={handleChange}
            placeholder="Additional Skills"
            className="w-full p-2 border rounded h-24"
          ></textarea>
          <input
            type="text"
            name="languages"
            value={profile.languages}
            onChange={handleChange}
            placeholder="Languages Spoken"
            className="w-full p-2 border rounded"
          />
          <textarea
            name="personalReferences"
            value={profile.personalReferences}
            onChange={handleChange}
            placeholder="Personal References"
            className="w-full p-2 border rounded h-24"
          ></textarea>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfilePanel;