import React, { useState, useEffect } from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import SearchBar from './SearchBar';
import OpportunityCard from './OpportunityCard';
import AuthPopup from './AuthPopup';
import ProfilePanel from './ProfilePanel';
import Pagination from './Pagination';
import Toast from './Toast';

function HomePage({ 
  user, 
  onSignIn, 
  onSignUp, 
  onSignOut,
  onUpdateProfile,
  carouselOpportunities,
  filteredOpportunities, 
  onSearch, 
  currentPage,
  totalPages,
  onPageChange,
  error, 
  message 
}) {
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [showProfilePanel, setShowProfilePanel] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [toast, setToast] = useState(null);

  useEffect(() => {
    if (error) {
      setToast({ message: error, type: 'error' });
    } else if (message) {
      setToast({ message, type: 'success' });
    }
  }, [error, message]);

  const handleSearch = (searchParams) => {
    onSearch(searchParams);
  };

  const handleAuthClick = (signIn = true) => {
    setIsSignIn(signIn);
    setShowAuthPopup(true);
  };

  const handleSignInSuccess = () => {
    setShowAuthPopup(false);
  };

  const handleProfileClick = () => {
    setShowProfilePanel(true);
  };

  const handleProfileSave = (profileData) => {
    onUpdateProfile(profileData);
    setShowProfilePanel(false);
    setToast({ message: 'Profile updated successfully', type: 'success' });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Header 
        user={user} 
        onSignOut={onSignOut} 
        onAuthClick={() => handleAuthClick(true)}
        onProfileClick={handleProfileClick}
      />
      <HeroSection user={user} opportunities={carouselOpportunities} onAuthClick={handleAuthClick} />
      <div className="container mx-auto px-4 py-8">
        <SearchBar onSearch={handleSearch} />
        {filteredOpportunities.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {filteredOpportunities.map((opportunity) => (
                <OpportunityCard 
                  key={opportunity.id} 
                  opportunity={{...opportunity, isFlagged: opportunity.isFlagged}} 
                />
              ))}
            </div>
            <Pagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <p>No opportunities found matching your search criteria.</p>
        )}
      </div>
      {showAuthPopup && (
        <AuthPopup
          onClose={() => setShowAuthPopup(false)}
          onSignIn={async (email, password) => {
            const success = await onSignIn(email, password);
            if (success) handleSignInSuccess();
          }}
          onSignUp={onSignUp}
          isSignIn={isSignIn}
        />
      )}
      {showProfilePanel && (
        <ProfilePanel
          user={user}
          onClose={() => setShowProfilePanel(false)}
          onSave={handleProfileSave}
        />
      )}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}

export default HomePage;